import { store } from '@/store/store';
import { ejercicio } from '@/shared/dtos/ejercicio';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { ejercicio_notas } from '@/shared/dtos/ejercicio-notas';

@Module({
    namespaced: true,
    name: 'ejercicioModule',
    store,
    dynamic: true
})
class ejercicioModule extends VuexModule {
    public ejercicios: ejercicio[] = [];
    public ejercicio: ejercicio = new ejercicio();
    public ejercicio_nota: ejercicio_notas = new ejercicio_notas();

    @Action({ commit: 'onGetejercicios' })
    public async getejercicios() {
        return await ssmHttpService.get(API.ejercicio);
    }

    @Action({ commit: 'onGetejercicio' })
    public async getejercicio(id: any) {
        return await ssmHttpService.get(API.ejercicio + '/' + id);
    }

    @Action
    public async guardarejercicio(ejercicio: ejercicio) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.ejercicio, ejercicio.toJson());
    }

    @Action
    public async modificarejercicio(ejercicio: ejercicio) {
        await ssmHttpService.put(API.ejercicio, ejercicio);
    }

    @Action({ commit: 'onGetejercicioNotas' })
    public async getejercicio_notas(id: any) {
        return await ssmHttpService.get(API.ejercicio + '/nota/' + id);
    }

    @Action
    public async guardarejercicio_notas(ejercicio: ejercicio_notas) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.ejercicio+ '/nota/', ejercicio.toJson());
    }

    @Action
    public async modificarejercicio_notas(ejercicio: ejercicio_notas) {
        await ssmHttpService.put(API.ejercicio+ '/nota/', ejercicio);
    }


    @Action
    public async eliminarejercicio(ejercicio: ejercicio) {
        await ssmHttpService.delete(API.ejercicio + '/' + ejercicio.id, null, false);
    }

    @Mutation
    public onGetejercicios(res: ejercicio[]) {

        this.ejercicios = res ? res.map((x) => new ejercicio(x)) : []
    }

    @Mutation
    public onGetejercicio(res: ejercicio) {
        this.ejercicio = new ejercicio(res);
    }
    @Mutation
    public onGetejercicioNotas(res: ejercicio_notas) {
        this.ejercicio_nota = new ejercicio_notas(res);
    }

}
export default getModule(ejercicioModule);