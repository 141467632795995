















































































import { ejercicio } from "@/shared/dtos/ejercicio";
import { ejercicio_notas } from "@/shared/dtos/ejercicio-notas";
import ejercicioModule from "@/store/modules/ejercicio-module";
import EjercicioModule from "@/store/modules/ejercicio-module";
import entrevistaModule from "@/store/modules/entrevista-module";
import { UtilsString } from "@/utils/utils-string";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class EjercicioFicha extends Vue {
  public id_item: number = 0;
  public ejercicio: ejercicio = new ejercicio();
  public dialog: boolean = false;
  created() {
    this.id_item = Number.parseInt(this.$route.params.id);
    this.ejercicio = ejercicioModule.ejercicios.find(
      (x) => x.id === this.id_item
    )!;
    ejercicioModule.getejercicio_notas(this.ejercicio.id);
  }
  public get nota() {
    return ejercicioModule.ejercicio_nota;
  }
  public get peso() {
    let p = entrevistaModule.entrevista.peso;
    if (p === undefined || p === null || p === 0) {
      p = 1;
    }
    return p;
  }
  public contar(str:string){
    let stre = str.toString().split(".");
    if(stre.length===1){
      return str;
    }

   return stre[0]+'.'+UtilsString.cortarStringLargo(stre[1],2);
  }
  public CloseDialog(save: boolean) {
    if (save) {
      this.nota.id_ejercicio = this.ejercicio.id;
      if (
        this.nota.id === undefined ||
        this.nota.id === null ||
        this.nota.id === 0
      ) {
        ejercicioModule.guardarejercicio_notas(new ejercicio_notas(this.nota));
      } else {
        ejercicioModule.modificarejercicio_notas(
          new ejercicio_notas(this.nota)
        );
      }
    }
    this.dialog = false;
  }
}
