import { BaseDto } from '@/shared/dtos/base-dto';

export class ejercicio extends BaseDto {
    public nombre_ejercicio !: string;
    public ruta_img_gif !: string;
    public detalles !: string;
    public kcal_kg_h_min !: number;
    public kcal_kg_h_max !: number;
    public sistema !: boolean;
    public id_nutricionista !: number;
} 
