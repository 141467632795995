import { BaseDto } from './base-dto';

export class entrevistaDto extends BaseDto {
    public numero_paciente !: number;
    public fecha_nacimiento !: Date;
    public primera_consulta!:boolean;
    public sexo !: string;
    public peso !: number;
    public porcen_grasa !: number;
    public cintura !: number;
    public cadera !: number;
    public muñeca !: number;
    public altura !: number;
    public temporada !: string;
    public alimentacion !: string;
    public turno_laboral !: string;
    public practica_deporte !: string;
    public actividad_fisica !: string;
    public objetivo !: string;
    public id_objetivo !: number;
    public calorias_dieta !: number;
    public proteinas_dieta !: number;
    public grasas_dieta !: number;
    public glucidos_dieta !: number;
    public fecha_entrevista !: Date;
    public anotaciones_html!:string;
    public id_cita!:string;
} 
