import { store } from '@/store/store';
import { entrevistaDto } from '@/shared/dtos/entrevistaDto';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'entrevistaModule',
    store,
    dynamic: true
})
class entrevistaModule extends VuexModule {
    public entrevistas: entrevistaDto[] = [];
    public entrevista: entrevistaDto = new entrevistaDto();

    @Action({ commit: 'onGetentrevista' })
    public async getultimaentrevista() {
        return await ssmHttpService.get(API.entrevista + '/ultima_medicion_paciente/');
    }

    @Action({ commit: 'onGuardarEntrevista' })
    public async guardarentrevista(obj_entrevista: entrevistaDto) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        let mi_entrevista: entrevistaDto = new entrevistaDto();
        //mi_entrevista.id = obj_entrevista.id;
        mi_entrevista.actividad_fisica = obj_entrevista.actividad_fisica;
        mi_entrevista.alimentacion = obj_entrevista.alimentacion;
        mi_entrevista.altura = obj_entrevista.altura;
        mi_entrevista.cadera = obj_entrevista.cadera;
        mi_entrevista.calorias_dieta = obj_entrevista.calorias_dieta;
        mi_entrevista.cintura = obj_entrevista.cintura;
        mi_entrevista.fecha_entrevista = obj_entrevista.fecha_entrevista;
        mi_entrevista.fecha_nacimiento = obj_entrevista.fecha_nacimiento;
        mi_entrevista.grasas_dieta = obj_entrevista.grasas_dieta;
        mi_entrevista.id_objetivo = obj_entrevista.id_objetivo;
        mi_entrevista.muñeca = obj_entrevista.muñeca;
        mi_entrevista.numero_paciente = obj_entrevista.numero_paciente;
        mi_entrevista.objetivo = obj_entrevista.objetivo;
        mi_entrevista.peso = obj_entrevista.peso;
        //debe de ser un numero entero con lo que un decimal no nos vale, acercamos a el decimal más proximo
        mi_entrevista.porcen_grasa = Math.ceil(obj_entrevista.porcen_grasa);
        mi_entrevista.practica_deporte = obj_entrevista.practica_deporte;
        mi_entrevista.proteinas_dieta = obj_entrevista.proteinas_dieta;
        mi_entrevista.sexo = obj_entrevista.sexo;
        mi_entrevista.temporada = obj_entrevista.temporada;
        mi_entrevista.turno_laboral = obj_entrevista.turno_laboral;
        mi_entrevista.anotaciones_html = obj_entrevista.anotaciones_html;
        mi_entrevista.id_cita = obj_entrevista.id_cita;
        return await ssmHttpService.post(API.entrevista, mi_entrevista.toJson());
    }


    @Mutation
    public onGuardarEntrevista(res: entrevistaDto) {
        this.entrevista = new entrevistaDto(res);
    }

    @Mutation
    public onGetentrevistas(res: entrevistaDto[]) {
        this.entrevistas = res ? res.map((x) => new entrevistaDto(x)) : [];
    }

    @Mutation
    public onGetentrevista(res: entrevistaDto) {
        this.entrevista = new entrevistaDto(res);
    }
}
export default getModule(entrevistaModule);